@import 'npm:tingle.js/src/tingle.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'christmas';
    src: url(../static/fonts/DancingScriptOT.eot);
    src: url(../static/fonts/DancingScriptOT.eot) format('embedded-opentype'),
        url(../static/fonts/DancingScriptOT.woff2) format('woff2'),
        url(../static/fonts/DancingScriptOT.woff) format('woff'),
        url(../static/fonts/DancingScriptOT.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    background-image: url(../static/seamless.png);
}

.calendar {
    height: 720px;
    width: 1161px;
    position: relative;
    background-image: url(../static/hintergrund.jpg);
    border-radius: 3px;
}

a.door {
    position: absolute;
    border: 1px solid #eee;
    border-radius: 3px;
    padding-top: 2em;
    padding-left: 1.5em;
    padding-right: 0.5em;
    font-size: 1.0em;
    background-color: rgb(255, 255, 255, 0.15);
    font-family: "christmas";
}

a.door:hover {
    background-color: rgb(255, 255, 255, 0.75);
}

@media (pointer:none), (pointer:coarse) {
    a.door {
        background-color: rgb(255, 255, 255, 0.75);
    }
}

p.quote {
    padding: 5px;
    text-align: center;
    font-family: "christmas";
    font-size: x-large;
    color: white;
    width: min-content;
    min-width: 100%;
}

.tingle-modal {
    background: rgba(0, 0, 0, .3);
}

.tingle-modal--overflow {
    padding-top: 0;
}

.tingle-modal-box {
    border-radius: 3px;
    background-image: url(../static/seamless.png);
    opacity: 1;
    width: fit-content;
}

.tingle-modal-box__content {
    padding: 4px;
}

.tingle-modal-box__footer {
    font-family: "christmas";
    font-size: 2em;
    color: white;
    padding: 4px 12px;
    width: auto;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: transparent;
}

.tingle-modal--visible .tingle-modal-box {
    animation: scale .4s cubic-bezier(.68, -.55, .265, 1.55) forwards;
  }