.tingle-modal * {
  box-sizing: border-box;
}

.tingle-modal {
  z-index: 1000;
  visibility: hidden;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  cursor: url("data:image/svg+xml,%3Csvg width='19' height='19' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.514.535l-6.42 6.42L2.677.536a1.517 1.517 0 00-2.14 0 1.517 1.517 0 000 2.14l6.42 6.419-6.42 6.419a1.517 1.517 0 000 2.14 1.517 1.517 0 002.14 0l6.419-6.42 6.419 6.42a1.517 1.517 0 002.14 0 1.517 1.517 0 000-2.14l-6.42-6.42 6.42-6.418a1.517 1.517 0 000-2.14 1.516 1.516 0 00-2.14 0z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E"), auto;
  background: #000000e6;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

@supports ((-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))) {
  .tingle-modal {
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
  }
}

.tingle-modal--confirm .tingle-modal-box {
  text-align: center;
}

.tingle-modal--noOverlayClose {
  cursor: default;
}

.tingle-modal--noClose .tingle-modal__close {
  display: none;
}

.tingle-modal__close {
  z-index: 1000;
  color: #fff;
  cursor: pointer;
  background-color: #0000;
  border: none;
  width: 2rem;
  height: 2rem;
  padding: 0;
  position: fixed;
  top: 2.5rem;
  right: 2.5rem;
}

.tingle-modal__close svg * {
  fill: currentColor;
}

.tingle-modal__closeLabel {
  display: none;
}

.tingle-modal__close:hover {
  color: #fff;
}

.tingle-modal-box {
  opacity: 1;
  cursor: auto;
  will-change: transform, opacity;
  background: #fff;
  border-radius: 4px;
  flex-shrink: 0;
  width: 60%;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}

.tingle-modal-box__content {
  padding: 3rem;
}

.tingle-modal-box__footer {
  cursor: auto;
  background-color: #f5f5f5;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  width: auto;
  padding: 1.5rem 2rem;
}

.tingle-modal-box__footer:after {
  clear: both;
  content: "";
  display: table;
}

.tingle-modal-box__footer--sticky {
  z-index: 10001;
  opacity: 1;
  transition: bottom .3s ease-in-out .3s;
  position: fixed;
  bottom: -200px;
}

.tingle-enabled {
  position: fixed;
  left: 0;
  right: 0;
  overflow: hidden;
}

.tingle-modal--visible .tingle-modal-box__footer {
  bottom: 0;
}

.tingle-modal--visible {
  visibility: visible;
  opacity: 1;
}

.tingle-modal--visible .tingle-modal-box {
  animation: .2s cubic-bezier(.68, -.55, .265, 1.55) forwards scale;
}

.tingle-modal--overflow {
  padding-top: 8vh;
  overflow-y: scroll;
}

.tingle-btn {
  box-shadow: none;
  color: #fff;
  vertical-align: middle;
  font-size: inherit;
  cursor: pointer;
  background-color: gray;
  border: none;
  margin: 0 .5rem;
  padding: 1rem 2rem;
  font-family: inherit;
  line-height: normal;
  text-decoration: none;
  transition: background-color .4s;
  display: inline-block;
}

.tingle-btn--primary {
  background-color: #3498db;
}

.tingle-btn--danger {
  background-color: #e74c3c;
}

.tingle-btn--default {
  background-color: #34495e;
}

.tingle-btn--pull-left {
  float: left;
}

.tingle-btn--pull-right {
  float: right;
}

@media (width <= 540px) {
  .tingle-modal {
    width: 100%;
    padding-top: 60px;
    display: block;
    top: 0;
  }

  .tingle-modal-box {
    border-radius: 0;
    width: auto;
  }

  .tingle-modal-box__content {
    overflow-y: scroll;
  }

  .tingle-modal--noClose {
    top: 0;
  }

  .tingle-modal--noOverlayClose {
    padding-top: 0;
  }

  .tingle-modal-box__footer .tingle-btn {
    float: none;
    width: 100%;
    margin-bottom: 1rem;
    display: block;
  }

  .tingle-modal__close {
    box-shadow: none;
    color: #fff;
    background-color: #2c3e50;
    border: none;
    width: 100%;
    height: 60px;
    display: block;
    top: 0;
    left: 0;
    right: 0;
  }

  .tingle-modal__closeLabel {
    vertical-align: middle;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 1.6rem;
    display: inline-block;
  }

  .tingle-modal__closeIcon {
    vertical-align: middle;
    width: 1.6rem;
    margin-right: .8rem;
    font-size: 0;
    display: inline-block;
  }
}

@keyframes scale {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.flex {
  display: flex;
}

.min-h-screen {
  min-height: 100vh;
}

.min-w-fit {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

@font-face {
  font-family: christmas;
  src: url("DancingScriptOT.3d419d5b.eot");
  src: url("DancingScriptOT.3d419d5b.eot") format("embedded-opentype"), url("DancingScriptOT.49d781bb.woff2") format("woff2"), url("DancingScriptOT.b09a61fb.woff") format("woff"), url("DancingScriptOT.f6cda2b0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  background-image: url("seamless.8e30fce4.png");
}

.calendar {
  background-image: url("hintergrund.94bf8ff5.jpg");
  border-radius: 3px;
  width: 1161px;
  height: 720px;
  position: relative;
}

a.door {
  background-color: #ffffff26;
  border: 1px solid #eee;
  border-radius: 3px;
  padding-top: 2em;
  padding-left: 1.5em;
  padding-right: .5em;
  font-family: christmas;
  font-size: 1em;
  position: absolute;
}

a.door:hover {
  background-color: #ffffffbf;
}

@media (pointer: none), (pointer: coarse) {
  a.door {
    background-color: #ffffffbf;
  }
}

p.quote {
  text-align: center;
  color: #fff;
  width: min-content;
  min-width: 100%;
  padding: 5px;
  font-family: christmas;
  font-size: x-large;
}

.tingle-modal {
  background: #0000004d;
}

.tingle-modal--overflow {
  padding-top: 0;
}

.tingle-modal-box {
  opacity: 1;
  background-image: url("seamless.8e30fce4.png");
  border-radius: 3px;
  width: -moz-fit-content;
  width: fit-content;
}

.tingle-modal-box__content {
  padding: 4px;
}

.tingle-modal-box__footer {
  color: #fff;
  background-color: #0000;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  width: auto;
  padding: 4px 12px;
  font-family: christmas;
  font-size: 2em;
}

.tingle-modal--visible .tingle-modal-box {
  animation: .4s cubic-bezier(.68, -.55, .265, 1.55) forwards scale;
}

/*# sourceMappingURL=index.85b0033a.css.map */
